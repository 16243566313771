/**
 * Site header
 */
.site-header {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $grey-color-dark;
  }
}

.site-nav {
	float: right;
	line-height: $base-line-height * $base-font-size * 2.25;
	list-style: none;

	.nav-trigger {
		display: none;
	}

	.menu-icon {
		display: none;
	}

	.page-link {
		color: $text-color;
		line-height: $base-line-height;
		margin: 0px;
		padding: 10px 5px;

		// Gaps between nav items, but not on the last one
		//&:not(:last-child) {
		//	padding-right: 0px;
		//}
	}
  
	.flex-container {
		display: flex;
		flex-flow: row wrap;
		margin: 0px;
	}
  
	li {
		list-style: none;
		margin: 0px;
	}
	
	.nav-dropdown {
		position: relative;
	}
  
	a.nav-dropdown:hover {
		background-color: lightgray;
		background-clip: border-box;
	}
  
	.nav-dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		z-index: 1;
		margin: 0px;
	}
	
	.nav-dropdown:hover .nav-dropdown-content {
		display: flex;
		flex-flow: column wrap;
		align-items: flex-end;
		right: 0px;
		white-space: nowrap;
		
		.page-link {
			padding: 5px;
		}
	}

	@include media-query($on-palm) {
		position: absolute;
		top: 9px;
		right: $spacing-unit / 2;
		background-color: $background-color;
		border: 1px solid $grey-color-light;
		border-radius: 5px;
		text-align: right;
	
		label[for="nav-trigger"] {
			display: block;
			float: right;
			width: 36px;
			height: 36px;
			z-index: 2;
			cursor: pointer;
		}
	
		.menu-icon {
			display: block;
			float: right;
			width: 36px;
			height: 26px;
			line-height: 0;
			padding-top: 10px;
			text-align: center;
		
			> svg {
				fill: $grey-color-dark;
			}
		}
		
		input ~ .trigger {
			clear: both;
			display: none;
		}
		
		input:checked ~ .trigger {
			display: block;
			padding-bottom: 5px;
		}
		
		.page-link {
			display: block;
			padding: 5px 10px;
			margin: unset;
		
			&:not(:last-child) {
				margin-right: 0;
			}
			
			margin-left: 20px;
		}
		
		.flex-container {
			display: flex;
			flex-flow: column wrap;
			margin: 0px;
		}
		
		.nav-dropdown:hover .nav-dropdown-content {
			display: none;
		}
	}
}

.flex-container {
	display: flex;
	flex-flow: row wrap;
}

.flex-container-space {
	justify-content: space-between;
}

.sidebar-right {
	padding-right: 0;
	float: right;
	height: auto; /* Full-height: remove this if you want "auto" height */
	width: 150px; /* Set the width of the sidebar */
	z-index: 1; /* Stay on top */
	top: 0; /* Stay at the top */
	right: 0;
	overflow-x: hidden; /* Disable horizontal scroll */
	margin-left: 5px;
	border-left: 1px solid;
	padding-left: 5px;

	.page-link {
		color: $text-color;
		line-height: $base-line-height;
		/*
		// Gaps between nav items, but not on the last one
		&:not(:last-child) {
			margin-right: 20px;
		}*/
	}
}

.form-control {
	margin-top: 0;
	margin-bottom: 0;	
	
	h4 {
		margin-top: $spacing-unit / 2;
		margin-bottom: 0;
	}
	
	input {
		margin-top: 0;
		margin-bottom: $spacing-unit / 2;
	}
}

/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
  
  .half-hr {
	width: 50%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
	margin-top: 10px
  }
}

ul.bullet {
	list-style: disc;
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}

.post-voice {
  font-size: $small-font-size;
  color: $grey-color-dark;
}

.post-location {
  font-family: "Courier New", Monospace;
  font-size: $small-font-size;
  color: $grey-color-dark;
  text-transform: uppercase;
}

.image-table {
  width: 100%;
  margin: 20px auto;
  table-layout: fixed;
  text-align: center;
  empty-cells: hide;
  border: "0";
  border-spacing: "7";
  width: "100%";
  
  td.col3 {
    width: calc(100% /3);
  }

  td.col2 {
    width: calc(100% /2);
  }

  td.empty {
    border: none;
  }
  
  img {
    display: block;
	width: 100%;
  }
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
	margin-bottom: $spacing-unit;
	
	h2 {
		@include relative-font-size(2);
		
		@include media-query($on-laptop) {
			@include relative-font-size(1.75);
		}
	}
	
	h3 {
		@include relative-font-size(1.625);
		
		@include media-query($on-laptop) {
			@include relative-font-size(1.375);
		}
	}
	
	h4 {
		@include relative-font-size(1.25);
		
		@include media-query($on-laptop) {
			@include relative-font-size(1.125);
		}
	}
	
	.horizontal-list {
		margin-bottom: 5px;
	
		li {
			//  margin-bottom: 0px;
		}
	}
	
	.half-hr {
		width: 50%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10px;
		margin-top: 10px
	}
	
	.skills {
		width: 50%;
		
		@include media-query($on-palm) {
			width: 100%;
		}
	}
	
	.non-technical {
		padding: 0px 20px 10px 0px;
	}
	
	p {
		page-break-inside: avoid;
	}
}