//Print view

@media print {
	
	.post, .post-content, .post-title, .post-list, .page-content {
		@include relative-font-size(0.70);
		border: none;
		padding: unset;
		margin: unset;
		
		.post-header, .post-title {
			@include relative-font-size(1.2);
		}
		
		h1, h2, h3, h4, li, p, hr {
			margin-bottom: 10px;
		}
		
		h1 {
			@include relative-font-size(1.4);
		}
		
		h2 {
			@include relative-font-size(1.2);
		}
	
		h3 {
			@include relative-font-size(1.0);
		}
	
		h4 {
			@include relative-font-size(0.9);
		}
		
		table, blockquote, pre, code, figure, li, hr, ul, ol, a, tr {
			page-break-inside: avoid;
		}
		
		h1, h2, h3, h4, h5, h6 {
			page-break-after: avoid;
			page-break-inside: avoid;
		}

		h1 + p,
		h2 + p,
		h3 + p {
			page-break-before: avoid;
		}

		img {
			page-break-after: auto;
			page-break-before: auto;
			page-break-inside: avoid;
		}
		
		h1 + table,
		h2 + table,
		h3 + table,
		h3 + table {
			page-break-before: avoid;
		}
		
		.horizontal-list {
			margin-bottom: 0px;
			
			li {
				margin-bottom: 0px;
			}
		}
		
		.half-hr {
			width: 50%;
			margin-left: auto;
			margin-right: auto;
		}
		
		.skills {
			width: 33%;
		}

		li {
				margin-bottom: 0px;
			}
	}

	.post-list {
  		margin-left: 0;
  		list-style: none;

	  > li {
	    margin-bottom: $spacing-unit / 3;
	  }
	}
	
	header nav.site-nav, footer, div.sidebar-right, .rss-subscribe, .post-header {
		display: none;
	}
	
	.site-header {
		@include relative-font-size(0.75);
		border: none;
		padding: unset;
		margin: unset;
	}
	
	@page {
		margin: 0.5cm;
		border: none;
	}
	
	.print-block {
		page-break-inside: avoid;
	}
}